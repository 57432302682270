import { isCategoryMatter, getCategoryFromMatterNumber } from '../../utils/categories';
import autoBind from 'class-autobind';
import CategoriesInput from '../categories/CategoriesInput';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import CheckButton from '../shared/CheckButton';
import HelpIcon from '../shared/HelpIcon';

export default class BatchInputOptions extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    values: PropTypes.object.isRequired,
    matter: PropTypes.instanceOf(Immutable.Map),
    categories: PropTypes.instanceOf(Immutable.List),
    viewCategory: PropTypes.func
  };

  constructor(props) {
    super(props);
    autoBind(this);
  }

  hasCategories() {
    return Boolean(this.props.categories && this.props.viewCategory && this.props.matter);
  }

  onChangeCited(cited) {
    this.props.onChange({ cited });
  }

  onChangeCitedOnIDS(citedOnIDS) {
    this.props.onChange({
      citedOnIDS
    });
  }

  onChangeCitedOn892(citedOn892) {
    this.props.onChange({
      citedOn892
    });
  }

  renderOption({ checkbox, label, help }) {
    return (
      <div style={{ margin: '5px 0px', position: 'relative', left: '-2px', display: 'flex', alignItems: 'center', gap: '10px' }}>
        {checkbox}
        <span>{label}</span>
        {help
          ? (
            <HelpIcon
              icon='info-circle'
              help={help}
            />
          )
          : null}
      </div>
    );
  }

  renderCited() {
    return this.renderOption({
      checkbox: (
        <CheckButton
          checked={this.props.values.cited}
          onChange={this.onChangeCited}
        />
      ),
      label: 'Mark documents as cited'
    });
  }

  renderCitedOnIDS() {
    return this.renderOption({
      checkbox: (
        <CheckButton
          checked={this.props.values.citedOnIDS}
          onChange={this.onChangeCitedOnIDS}
        />
      ),
      label: 'Cited on IDS',
      help: 'Add a matter note to the document indicating that it was cited on IDS.'
    });
  }

  renderCitedOn892() {
    return this.renderOption({
      checkbox: (
        <CheckButton
          checked={this.props.values.citedOn892}
          onChange={this.onChangeCitedOn892}
        />
      ),
      label: 'Cited on 892',
      help: 'Add a matter note to the document indicating that it was cited on 892.'
    });
  }

  getCategories() {
    const matterNumber = this.props.matter.get('matterNumber');
    if (isCategoryMatter(matterNumber)) {
      const category = getCategoryFromMatterNumber(matterNumber);
      if (!this.props.values.selectedCategories.includes(category)) {
        return this.props.values.selectedCategories.concat(category);
      }
    }
    return this.props.values.selectedCategories;
  }

  onSelectCategory(selectedCategories) {
    this.props.onChange({ selectedCategories });
  }

  renderCategory() {
    const categories = this.getCategories();

    return this.renderOption({
      checkbox: (
        <div style={{ display: 'inline-block' }}>
          <CategoriesInput
            compact
            onConfirm={this.onSelectCategory}
            id='batch-sweep-modal-categories-dropdown'
            categories={this.props.categories}
            selectedCategories={categories}
            viewCategory={this.props.viewCategory}
          />
        </div>
      ),
      label: categories.count() ? categories.join(', ') : 'Select categories'
    });
  }

  render() {
    return (
      <>
        {this.renderCited()}
        {this.renderCitedOnIDS()}
        {this.renderCitedOn892()}
        {this.hasCategories() ? this.renderCategory() : null}
      </>
    );
  }
}
