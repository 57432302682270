export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const AUTH_CANCEL_CLICK = 'AUTH_CANCEL_CLICK';
export const AUTH_LOGIN_MESSAGE = 'AUTH_LOGIN_MESSAGE';
export const AUTH_LOGIN_PROGRESS = 'AUTH_LOGIN_PROGRESS';
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_OPEN_MODAL = 'AUTH_OPEN_MODAL';
export const AUTH_PASSWORD_MESSAGE = 'AUTH_PASSWORD_MESSAGE';
export const AUTH_PASSWORD_PROGRESS = 'AUTH_PASSWORD_PROGRESS';
export const AUTH_PASSWORD_SUCCESS = 'AUTH_PASSWORD_SUCCESS';
export const AUTH_REGISTER_MESSAGE = 'AUTH_REGISTER_MESSAGE';
export const AUTH_REGISTER_PROGRESS = 'AUTH_REGISTER_PROGRESS';
export const AUTH_REGISTER_SUCCESS = 'AUTH_REGISTER_SUCCESS';
export const AUTH_RESET_MESSAGE = 'AUTH_RESET_MESSAGE';
export const AUTH_RESET_PROGRESS = 'AUTH_RESET_PROGRESS';
export const AUTH_RESET_SUCCESS = 'AUTH_RESET_SUCCESS';
export const BATCH_MATTER_INPUT_CONFIRM = 'BATCH_MATTER_INPUT_CONFIRM';
export const BATCH_MATTER_INPUT_ERROR = 'BATCH_MATTER_INPUT_ERROR';
export const BATCH_UPLOAD_DOCUMENTS_CLOSE = 'BATCH_UPLOAD_DOCUMENTS_CLOSE';
export const BATCH_UPLOAD_DOCUMENTS_CONFIRM = 'BATCH_UPLOAD_DOCUMENTS_CONFIRM';
export const BATCH_UPLOAD_DOCUMENTS_LOAD = 'BATCH_UPLOAD_DOCUMENTS_LOAD';
export const CITE_CHRON_SUBMIT = 'CITE_CHRON_SUBMIT';
export const CITE_CHRON_UPDATE = 'CITE_CHRON_UPDATE';
export const CLEANUP_TWOWAY_BEGIN = 'CLEANUP_TWOWAY_BEGIN';
export const CLEANUP_TWOWAY_CLOSE = 'CLEANUP_TWOWAY_CLOSE';
export const CLEANUP_TWOWAY_ERROR = 'CLEANUP_TWOWAY_ERROR';
export const CLEANUP_TWOWAY_SUCCESS = 'CLEANUP_TWOWAY_SUCCESS';
export const CLEAR_NPL_DOCUMENTS = 'CLEAR_NPL_DOCUMENTS';
export const CLIENT_MATTER_SELECT = 'CLIENT_MATTER_SELECT';
export const CLIENT_SELECT = 'CLIENT_SELECT';
export const CLOSE_CATEGORY = 'CLOSE_CATEGORY';
export const CLOSE_SYNC_OVERLAP = 'CLOSE_SYNC_OVERLAP';
export const DELETE_DOCUMENT_SUCCESS = 'DELETE_DOCUMENT_SUCCESS';
export const DOCUMENT_ADD = 'DOCUMENT_ADD';
export const DOCUMENT_ADD_SUCCESS = 'DOCUMENT_ADD_SUCCESS';
export const DOCUMENT_REPLACE_SUCCESS = 'DOCUMENT_REPLACE_SUCCESS';
export const DOCUMENTS_CITED_FILTER = 'DOCUMENTS_CITED_FILTER';
export const DOCUMENTS_NOTES_TAB = 'DOCUMENTS_NOTES_TAB';
export const DOCUMENTS_SEARCH_TAB = 'DOCUMENTS_SEARCH_TAB';
export const LOAD_ATTORNEY_LIST_BEGIN = 'LOAD_ATTORNEY_LIST_BEGIN';
export const LOAD_ATTORNEY_LIST_ERROR = 'LOAD_ATTORNEY_LIST_ERROR';
export const LOAD_ATTORNEY_LIST_SUCCESS = 'LOAD_ATTORNEY_LIST_SUCCESS';
export const LOAD_MATTER_NOTES = 'LOAD_MATTER_NOTES';
export const LOAD_SYNC_OVERLAP_BEGIN = 'LOAD_SYNC_OVERLAP_BEGIN';
export const LOAD_SYNC_OVERLAP_ERROR = 'LOAD_SYNC_OVERLAP_ERROR';
export const LOAD_SYNC_OVERLAP_SUCCESS = 'LOAD_SYNC_OVERLAP_SUCCESS';
export const LOCATION_CHANGE = 'LOCATION_CHANGE';
export const MATTER_CHECK_CONSISTENCY = 'MATTER_CHECK_CONSISTENCY';
export const MATTER_CLEAR_CONSISTENCY = 'MATTER_CLEAR_CONSISTENCY';
export const MATTER_RENAME_SUCCESS = 'MATTER_RENAME_SUCCESS';
export const MATTER_SELECT = 'MATTER_SELECT';
export const MERGE_SYNC_OVERLAP_BEGIN = 'MERGE_SYNC_OVERLAP_BEGIN';
export const MERGE_SYNC_OVERLAP_ERROR = 'MERGE_SYNC_OVERLAP_ERROR';
export const MERGE_SYNC_OVERLAP_SUCCESS = 'MERGE_SYNC_OVERLAP_SUCCESS';
export const MIGRATION_CLEAR_NPLMATCH = 'MIGRATION_CLEAR_NPLMATCH';
export const MIGRATION_CLEAR_TWOWAY = 'MIGRATION_CLEAR_TWOWAY';
export const MIGRATION_CONFIRM_TWOWAY = 'MIGRATION_CONFIRM_TWOWAY';
export const MIGRATION_MATCH_NPLMATCH = 'MIGRATION_MATCH_NPLMATCH';
export const MIGRATION_RENDER_NPLMATCH = 'MIGRATION_RENDER_NPLMATCH';
export const MIGRATION_UPLOAD_TWOWAY = 'MIGRATION_UPLOAD_TWOWAY';
export const NEAR_DUPLICATE_BEGIN = 'NEAR_DUPLICATE_BEGIN';
export const NEAR_DUPLICATE_CLEAR = 'NEAR_DUPLICATE_CLEAR';
export const NEAR_DUPLICATE_ERROR = 'NEAR_DUPLICATE_ERROR';
export const NEAR_DUPLICATE_PROGRESS = 'NEAR_DUPLICATE_PROGRESS';
export const NEAR_DUPLICATE_SUCCESS = 'NEAR_DUPLICATE_SUCCESS';
export const NPL_SUGGESTIONS = 'NPL_SUGGESTIONS';
export const NPLMATCH_UPLOAD_BEGIN = 'NPLMATCH_UPLOAD_BEGIN';
export const NPLMATCH_UPLOAD_ERROR = 'NPLMATCH_UPLOAD_ERROR';
export const NPLMATCH_UPLOAD_PROGRESS = 'NPLMATCH_UPLOAD_PROGRESS';
export const NPLMATCH_UPLOAD_SUCCESS = 'NPLMATCH_UPLOAD_SUCCESS';
export const PENDING_OFFICE_ACTIONS = 'PENDING_OFFICE_ACTIONS';
export const PRIOR_ART_SUBMIT = 'PRIOR_ART_SUBMIT';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';
export const SEARCH_NPL_DOCUMENTS = 'SEARCH_NPL_DOCUMENTS';
export const SELECT_CLIENT_TAB = 'SELECT_CLIENT_TAB';
export const SELECT_MATTER_TAB = 'SELECT_MATTER_TAB';
export const SET_FILE_REMINDER = 'SET_FILE_REMINDER';
export const TEST_DRIVE_SUBMIT = 'TEST_DRIVE_SUBMIT';
export const THEME_CHANGE = 'THEME_CHANGE';
export const TODOS_FETCH_PARAMS = 'TODOS_FETCH_PARAMS';
export const TODOS_FETCH_START = 'TODOS_FETCH_START';
export const TODOS_FETCH_STOP = 'TODOS_FETCH_STOP';
export const TODOS_SELECT_PAGE = 'TODOS_SELECT_PAGE';
export const TODOS_SET_PAGE_SIZE = 'TODOS_SET_PAGE_SIZE';
export const TODOS_SET_SORT = 'TODOS_SET_SORT';
export const TOGGLE_CLIENT_MATTER = 'TOGGLE_CLIENT_MATTER';
export const TOGGLE_PTO_FEE_PANEL = 'TOGGLE_PTO_FEE_PANEL';
export const TOGGLE_SYNC_PANEL = 'TOGGLE_SYNC_PANEL';
export const TOGGLE_WIDE_LAYOUT = 'TOGGLE_WIDE_LAYOUT';
export const UPDATE_CITED = 'UPDATE_CITED';
export const UPDATE_MATTER_NOTES = 'UPDATE_MATTER_NOTES';
export const UPDATE_PAIR_STATUS = 'UPDATE_PAIR_STATUS';
export const VIEW_CATEGORY = 'VIEW_CATEGORY';
