import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import ClientContainer from '../client/ClientContainer';
import DashboardContainer from '../dashboard/DashboardContainer';
import DocumentsContainer from '../documents/DocumentsContainer';
import MatterContainer from '../matter/MatterContainer';
import ToastersContainer from '../toasters/ToastersContainer';
import MigrationContainer from '../migration/MigrationContainer';
import SyncContainer from '../sync/SyncContainer';
import PtoFeeContainer from '../pto-fee/PtoFeeContainer';

export default class App extends Component {
  static propTypes = {
    wideLayout: PropTypes.bool,
    features: PropTypes.object.isRequired,
    preventUnload: PropTypes.bool
  };

  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentDidUpdate() {
    if (this.props.preventUnload) {
      window.onbeforeunload = function onbeforeunload() {
        return 'There is work in progress. Are you sure?';
      };
    } else {
      window.onbeforeunload = null;
    }
  }

  render() {
    return (
      <div
        className={this.props.wideLayout ? '' : 'container'}
        style={this.props.wideLayout ? { padding: '0 15px' } : null}
      >
        <div className='row'>
          <div className='col-lg-12'>
            <DashboardContainer />
          </div>
        </div>
        {this.props.features.ptoFeeSection && (
          <div className='row'>
            <div className='col-lg-12'>
              <PtoFeeContainer />
            </div>
          </div>
        )}
        <div className='row'>
          <div className='col-lg-6'>
            <ClientContainer />
          </div>
          <div className='col-lg-6'>
            <MatterContainer />
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-12'>
            <SyncContainer />
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-12'>
            <DocumentsContainer />
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-12'>
            <MigrationContainer />
          </div>
        </div>
        <ToastersContainer />
      </div>
    );
  }
}
