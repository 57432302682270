import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

export default class PtoFeeField extends Component {
  static propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
  };

  render() {
    return (
      <Button
        style={{ '--bs-btn-padding-y': '0.25rem', '--bs-btn-font-size': '13px' }}
        variant='secondary'
        size='small'
      >
        {this.props.value}
      </Button>
    );
  }
};
