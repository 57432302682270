const entitySizes = {
  Large: { value: 280, label: '$280' },
  Small: { value: 112, label: '$112' },
  Micro: { value: 56, label: '$56' }
};

export function getPtoTimingFee(matter, client) {
  const entitySize = matter.get('entitySize') || client?.get('entitySize');
  const timing = matter.get('timing');
  const statement = matter.get('statement');

  let ptoFee = { value: 0, label: '$0' };

  if (entitySizes[entitySize]) {
    switch (timing) {
      case '1':
        switch (statement) {
          case '2':
            ptoFee = entitySizes[entitySize];
            break;
        }
        break;
      case '2':
        ptoFee = entitySizes[entitySize];
        break;
    }
  }
  return ptoFee;
}
