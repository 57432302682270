import { createAction } from 'redux-actions';
import { TOGGLE_PTO_FEE_PANEL } from '../../constants/actions';

const togglePtoFeePanel = createAction(TOGGLE_PTO_FEE_PANEL);

export default (value) => (dispatch, getState) => {
  const {
    app: {
      expandedPtoFee
    }
  } = getState();

  const expanded = typeof value === 'boolean' ? value : !expandedPtoFee;

  dispatch(togglePtoFeePanel(expanded));
};
