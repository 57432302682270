import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import TodoRecord from '../../models/TodoRecord';

export default class TodoPtoFeeNavigator extends Component {
  static propTypes = {
    todo: PropTypes.instanceOf(TodoRecord).isRequired,
    togglePtoFeePanel: PropTypes.func.isRequired,
    selectClientAndMatter: PropTypes.func.isRequired
  };

  static defaultProps = {
    todo: new TodoRecord()
  };

  constructor(props) {
    super(props);
    autoBind(this);
  }

  onClick() {
    this.props.togglePtoFeePanel(true);

    this.props.selectClientAndMatter({
      clientId: this.props.todo.clientId,
      matterId: this.props.todo.matterId
    });

    document.querySelector('#pto-fee-panel')?.scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    });
  }

  render() {
    const timingFee = this.props.todo.getIn(['ptoFeeData', 'ptoTimingFee', 'label']);
    const sizeFee = this.props.todo.getIn(['ptoFeeData', 'ptoSizeFee', 'label']);

    return (
      <button
        style={{
          width: '130px',
          textAlign: 'left',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
        className='btn btn-sm btn-primary'
        onClick={this.onClick}
      >
        {`${timingFee} + ${sizeFee}`}
        <span className='fa fa-calculator' />
      </button>
    );
  }
};
