import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import Immutable from 'immutable';
import DropdownHelp from '../shared/DropdownHelp';

export default class PtoFeeDropdown extends Component {
  static propTypes = {
    matter: PropTypes.instanceOf(Immutable.Map).isRequired,
    field: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    updateMatter: PropTypes.func.isRequired,
    style: PropTypes.object
  };

  static getDerivedStateFromProps(props, state) {
    if (!Immutable.is(props.matter, state.matter)) {
      return {
        matter: props.matter,
        value: props.matter.get(props.field) || ''
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {};
  }

  onChange(value) {
    this.setState({ value });

    this.props.updateMatter({
      id: this.props.matter.get('id'),
      [this.props.field]: value
    }, this.props.matter.get('matterNumber'));
  }

  render() {
    return (
      <DropdownHelp
        id={`dropdown-pto-fee-${this.props.field}-${this.props.matter.get('id')}`}
        style={this.props.style}
        options={this.props.options}
        selected={this.state.value}
        onChange={this.onChange}
      />
    );
  }
}
