import { combineReducers } from 'redux';

import expandedClientMatter from './expandedClientMatter';
import expandedSyncPanel from './expandedSyncPanel';
import features from './features';
import preventUnload from './preventUnload';
import selectedClientId from './selectedClientId';
import selectedClientTab from './selectedClientTab';
import selectedMatterId from './selectedMatterId';
import selectedMatterTab from './selectedMatterTab';
import wideLayout from './wideLayout';
import maintenance from './maintenance';
import expandedPtoFeePanel from './expandedPtoFeePanel';

export default combineReducers({
  expandedClientMatter,
  expandedSyncPanel,
  expandedPtoFeePanel,
  features,
  preventUnload,
  selectedClientId,
  selectedClientTab,
  selectedMatterId,
  selectedMatterTab,
  wideLayout,
  maintenance
});
