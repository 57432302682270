import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';

const styleButton = {
  position: 'relative',
  width: '30px'
};

const styleCheckbox = {
  opacity: 0,
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  width: '100%',
  height: '100%',
  margin: 0
};

const styleIcon = {
  fontSize: '16px',
  position: 'relative',
  top: '2px'
};

export default class CheckButton extends Component {
  static propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
    checked: PropTypes.bool.isRequired,
    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    stopPropagation: PropTypes.bool,
    red: PropTypes.bool
  };

  static defaultProps = {
    stopPropagation: true
  };

  constructor(props) {
    super(props);
    autoBind(this);
  }

  onChange(e) {
    this.props.onChange(e.target.checked);
    e.target.blur();
  }

  onClick(e) {
    if (this.props.stopPropagation) {
      e.stopPropagation();
    }
  }

  renderChecked() {
    const color = this.props.red ? 'text-danger' : 'text-success';
    return (
      <span className={`fa fa-check-square-o ${color}`} style={styleIcon} />
    );
  }

  renderUnchecked() {
    return (
      <span className='fa fa-square-o' style={styleIcon} />
    );
  }

  render() {
    const { checked, disabled } = this.props;
    return (
      <div
        className={this.props.className || 'btn btn-sm btn-secondary'}
        style={{ ...styleButton, ...this.props.style }}
        onClick={this.onClick}
      >
        {checked ? this.renderChecked() : this.renderUnchecked()}
        <input
          type='checkbox'
          style={{
            cursor: this.props.disabled ? 'not-allowed' : 'pointer',
            ...styleCheckbox
          }}
          checked={checked}
          disabled={disabled}
          onChange={this.onChange}
        />
      </div>
    );
  }
}
