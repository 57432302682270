import { handleActions } from 'redux-actions';

import {
  TOGGLE_PTO_FEE_PANEL
} from '../../constants/actions';

const initialState = true;

export default handleActions({
  [TOGGLE_PTO_FEE_PANEL]: (state, { payload }) => (payload)
}, initialState);
