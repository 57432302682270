import _uniq from 'lodash/uniq';
import { isCategoryMatter, getCategoryFromMatterNumber } from '../../utils/categories';
import autoBind from 'class-autobind';
import { Button, Modal, Table } from 'react-bootstrap';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Field from '../shared/Field';
import Loading from '../shared/Loading';
import BatchInputOptions from './BatchInputOptions';

export default class BatchSweepDocuments extends Component {
  static propTypes = {
    officeAction: PropTypes.object,
    pdfs: PropTypes.array.isRequired,
    addBatchDocuments: PropTypes.func.isRequired,
    matter: PropTypes.instanceOf(Immutable.Map).isRequired,
    onClose: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    categories: PropTypes.instanceOf(Immutable.List).isRequired,
    viewCategory: PropTypes.func.isRequired,
    documents: PropTypes.instanceOf(Immutable.Map),
    showClientNotes: PropTypes.bool
  };

  static getDerivedStateFromProps(props, state) {
    return {
      documents: state.documents || props.documents
    };
  }

  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      show: true,
      cited: true,
      citedOnIDS: false,
      citedOn892: false,
      selectedCategories: Immutable.List(),
      edit: [],
      saving: false,
      documents: this.props.documents,
      notes: ''
    };
  }

  areAllDocumentsChecked() {
    if (!this.props.officeAction) {
      return true;
    }

    return Boolean(
      this.state.documents &&
      this.state.documents.map(docs => (
        docs.every(doc => doc.get('checked'))
      )).every(checked => checked)
    );
  }

  getNewDoc(type) {
    switch (type) {
      case 'US':
        return Immutable.Map({
          date: '',
          number: '',
          inventor: '',
          checked: true
        });
      case 'Foreign':
        return Immutable.Map({
          date: '',
          kind: '',
          number: '',
          country: '',
          applicant: '',
          checked: true
        });
      case 'Other':
        return Immutable.Map({
          description: '',
          checked: true
        });
    }
  }

  getCategories() {
    const matterNumber = this.props.matter.get('matterNumber');
    if (isCategoryMatter(matterNumber)) {
      const category = getCategoryFromMatterNumber(matterNumber);
      if (!this.state.selectedCategories.includes(category)) {
        return this.state.selectedCategories.concat(category);
      }
    }
    return this.state.selectedCategories;
  }

  onClickCancel() {
    this.setState({
      show: false
    });
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  }

  onClickConfirm() {
    let promise = Promise.resolve();
    const docsAdded = [];

    if (this.state.documents) {
      ['US', 'Foreign', 'Other']
        .filter(type => this.state.documents.get(type).size)
        .forEach(type => {
          promise = promise.then(() => {
            const docs = this.state.documents.get(type).toJS();
            docsAdded.push(...docs.map(doc => ({ ...doc, type })));
            return this.props.addBatchDocuments({
              type,
              matter: this.props.matter,
              cited: this.state.cited,
              citedOnIDS: this.state.citedOnIDS,
              citedOn892: this.state.citedOn892,
              docs,
              categories: this.getCategories(),
              officeAction: this.props.officeAction,
              notes: this.state.notes
            });
          });
        });
    }

    return promise
      .then(() => this.props.onConfirm(docsAdded))
      .then(() => this.onClose());
  }

  onClose() {
    this.setState({ saving: false, show: false });
    this.props.onClose();
  }

  renderConfirm() {
    return (
      <Button
        disabled={!this.areAllDocumentsChecked() || this.state.saving}
        variant='primary'
        onClick={this.onClickConfirm}
      >
        {this.state.saving ? 'Saving...' : 'Confirm'}
      </Button>
    );
  }

  renderCancel() {
    return (
      <Button
        style={{ marginRight: '10px' }}
        variant='secondary'
        onClick={this.onClickCancel}
      >
        Cancel
      </Button>
    );
  }

  onClickCheck(e) {
    const type = e.target.getAttribute('data-type');
    const index = Number(e.target.getAttribute('data-index'));
    const checked = !e.target.classList.contains('text-success');

    this.setState({
      documents: this.state.documents.setIn([type, index, 'checked'], checked)
    });
  }

  onClickRemove(e) {
    const type = e.target.getAttribute('data-type');
    const index = Number(e.target.getAttribute('data-index'));

    this.setState({
      documents: this.state.documents.deleteIn([type, index])
    });
  }

  onClickEdit(e) {
    const type = e.target.getAttribute('data-type');
    const index = Number(e.target.getAttribute('data-index'));

    this.toggleEdit(type, index);
  }

  toggleEdit(type, index) {
    const key = `${type}:${index}`;

    if (this.state.edit.includes(key)) {
      this.setState({
        edit: this.state.edit.filter(k => key !== k)
      });
    } else {
      this.setState({
        edit: _uniq(this.state.edit.concat(key))
      }, () => {
        const input = document.getElementById(`doc-${key}`);
        if (input) {
          input.focus();
        }
      });
    }
  }

  editDocument(e) {
    const type = e.target.getAttribute('data-type');
    const index = Number(e.target.getAttribute('data-index'));
    const field = type === 'Other' ? 'description' : 'number';

    let documents = this.state.documents;

    const currentValue = this.state.documents.getIn([type, index, field]);
    if (currentValue !== e.target.value) {
      let newValue = e.target.value;

      if (type === 'Foreign' && /[a-z]{2}/i.test(newValue)) {
        documents = documents.setIn([type, index, 'country'], newValue.substr(0, 2).toUpperCase());
        newValue = newValue.substr(2);
      }

      documents = documents.setIn([type, index, field], newValue);

      if (field === 'number') {
        documents = documents.setIn([type, index, 'date'], '');
        documents = documents.setIn([type, index, 'inventor'], '');
        documents = documents.setIn([type, index, 'applicant'], '');
      }
    }

    const newDocKey = `${type}:${index}`;

    this.setState({
      edit: this.state.edit.filter(key => key !== newDocKey),
      documents
    });
  }

  onEditKeyDown(e) {
    const type = e.target.getAttribute('data-type');
    const index = Number(e.target.getAttribute('data-index'));

    switch (e.which) {
      case 13: {
        this.editDocument(e);
        break;
      }
      case 27: {
        this.setState({
          edit: this.state.edit.filter(key => key !== `${type}:${index}`)
        });
        break;
      }
      default:
        break;
    }
  }

  onEditBlur(e) {
    this.editDocument(e);
  }

  renderDocNumber(type, doc, index) {
    const docKey = `${type}:${index}`;

    if (this.state.edit.includes(docKey)) {
      return (
        <div>
          <input
            id={`doc-${docKey}`}
            size={15}
            data-type={type}
            data-index={index}
            defaultValue={doc.get('number')}
            onBlur={this.onEditBlur}
            onKeyDown={this.onEditKeyDown}
          />
        </div>
      );
    }

    return (
      <div>
        {doc.get('number')}
      </div>
    );
  }

  renderNotes(type, doc, index) {
    return (
      <div>
        {doc.get('notes')}
      </div>
    );
  }

  renderDescription(type, doc, index) {
    const docKey = `${type}:${index}`;
    if (this.state.edit.includes(docKey)) {
      return (
        <div>
          <textarea
            id={`doc-${docKey}`}
            style={{ width: '100%' }}
            rows={3}
            data-type={type}
            data-index={index}
            defaultValue={doc.get('description')}
            onBlur={this.onEditBlur}
            onKeyDown={this.onEditKeyDown}
          />
        </div>
      );
    }

    return (
      <div>
        {doc.get('description')}
      </div>
    );
  }

  renderEdit(doc, type, index) {
    return (
      <span
        title='Edit'
        style={{ cursor: 'pointer' }}
        onClick={this.onClickEdit}
        data-type={type}
        data-index={index}
        className='fa fa-pencil'
      />
    );
  }

  renderRemove(doc, type, index) {
    return (
      <span
        title='Remove'
        style={{ cursor: 'pointer' }}
        data-type={type}
        data-index={index}
        className='fa fa-trash-o'
        onClick={this.onClickRemove}
      />
    );
  }

  renderCheck(doc, type, index) {
    return (
      <span
        style={{ cursor: 'pointer' }}
        data-type={type}
        data-index={index}
        className={doc.get('checked') ? 'fa fa-check-square-o text-success' : 'fa fa-times text-danger'}
        onClick={this.onClickCheck}
      />
    );
  }

  renderColumn(col, doc, type, index) {
    let content;
    const style = {};

    switch (col) {
      case 'number':
        style.width = type === 'Foreign' ? '160px' : '200px';
        content = this.renderDocNumber(type, doc, index);
        break;
      case 'description':
        content = this.renderDescription(type, doc, index);
        break;
      case 'country':
        style.width = '40px';
        break;
      case 'date': {
        style.width = '200px';
        const date = doc.get('date');
        if (date && /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/.test(date)) {
          const parts = date.split('-');
          content = `${parts[1]}-${parts[2]}-${parts[0]}`;
        }
        break;
      }
      case 'notes':
        content = this.renderNotes(type, doc, index);
        break;
      default:
        break;
    }

    return (
      <td key={col} style={style}>
        {content || doc.get(col) || '-'}
      </td>
    );
  }

  renderTable(type, docs, cols) {
    const labels = {
      number: 'Doc Number',
      date: 'Issue/Pub Date (Public)',
      inventor: 'Inventor (Public)',
      applicant: 'Applicant (Public)',
      country: 'CC',
      description: 'Description',
      notes: 'Client Notes'
    };

    return (
      <Table striped bordered hover>
        <thead>
          <tr>
            <th style={{ width: '30px' }} />
            <th style={{ width: '30px' }} />
            {cols.map((col) => (
              <th key={col}>{labels[col]}</th>
            ))}
            <th style={{ width: '30px' }}>QC</th>
          </tr>
        </thead>
        <tbody>
          {docs.map((doc, i) => {
            return (
              <tr key={i}>
                <td style={{ width: '30px' }} className='text-center'>
                  {this.renderRemove(doc, type, i)}
                </td>
                <td style={{ width: '30px' }} className='text-center'>
                  {this.renderEdit(doc, type, i)}
                </td>
                {cols.map((col) => (this.renderColumn(col, doc, type, i)))}
                <td style={{ width: '30px' }} className='text-center'>
                  {this.renderCheck(doc, type, i)}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  }

  onClickAdd(e) {
    const type = e.target.getAttribute('data-type');
    const docs = this.state.documents.get(type).push(this.getNewDoc(type));
    const index = docs.size - 1;

    this.setState({
      documents: this.state.documents.set(type, docs)
    }, () => {
      this.toggleEdit(type, index);
    });
  }

  renderDocuments({ label, type, cols }) {
    if (!this.state.documents) {
      return;
    }

    const docs = this.state.documents.get(type);

    return (
      <div className='row'>
        <div className='col-md-12'>
          <div style={{ padding: '10px 0' }}>
            <strong>{label} ({docs.size})</strong>
          </div>
          {docs.size > 0 && this.renderTable(type, docs, cols)}
          <Button
            data-type={type}
            onClick={this.onClickAdd}
            style={{ position: 'relative', top: '-10px' }}
            variant='primary'
            size='sm'
          >
            Add
          </Button>
        </div>
      </div>
    );
  }

  renderDocumentsTables() {
    const notes = this.props.showClientNotes ? ['notes'] : [];

    return (
      <>
        {this.renderDocuments({
          label: 'US Documents',
          type: 'US',
          cols: ['number', 'date', 'inventor', ...notes]
        })}
        {this.renderDocuments({
          label: 'Foreign Documents',
          type: 'Foreign',
          cols: ['number', 'country', 'date', 'applicant', ...notes]
        })}
        {this.renderDocuments({
          label: 'NPL Documents',
          type: 'Other',
          cols: ['description', ...notes]
        })}
      </>
    );
  }

  isLoading() {
    return this.props.officeAction && this.props.officeAction.textractStatus !== 1;
  }

  renderLoading() {
    return (
      <Loading text='Processing documents...' />
    );
  }

  renderDocumentsRow() {
    return (
      <div className='row'>
        <div className='col-md-12'>
          {this.isLoading() ? this.renderLoading() : this.renderDocumentsTables()}
        </div>
      </div>
    );
  }

  renderPDF(pdf, index) {
    return (
      <div className='col-md-2' key={index}>
        <div>
          <a
            className='btn btn-secondary btn-sm'
            disabled={!pdf.href}
            target='_blank'
            href={pdf.href} rel='noreferrer'
          >
            <span className={`fa ${pdf.href ? 'text-danger' : 'text-muted'} fa-file-pdf-o`} />
          </a>
          <span style={{ position: 'relative', top: '3px', left: '10px' }}>
            {pdf.label}
          </span>
        </div>
      </div>
    );
  }

  onChangeOptions(options) {
    this.setState(options);
  }

  renderOptionsRow() {
    return (
      <div className='row'>
        <div className='col-md-6'>
          <BatchInputOptions
            matter={this.props.matter}
            onChange={this.onChangeOptions}
            values={this.state}
            categories={this.props.categories}
            viewCategory={this.props.viewCategory}
          />
        </div>
        <div className='col-md-6'>
          {this.props.pdfs.map(this.renderPDF)}
          {this.renderRepeatNotes()}
        </div>
      </div>
    );
  }

  onChangeNotes(event) {
    this.setState({
      notes: event.target.value
    });
  }

  renderRepeatNotes() {
    return (
      <Field label='Client Notes'>
        <textarea
          className='form-control input-sm'
          rows={2}
          value={this.state.notes}
          onChange={this.onChangeNotes}
        />
      </Field>
    );
  }

  renderBody() {
    return (
      <div>
        {this.renderDocumentsRow()}
        <hr />
        {this.renderOptionsRow()}
      </div>
    );
  }

  renderDedupLink() {
    const term = encodeURIComponent(this.props.matter.get('clientNumber') + '|' + this.props.matter.get('matterNumber'));
    const url = `/#/app/show=all&showNearDuplicate=true&filterType=smart&term=${term}`;

    return (
      <Button
        target='_blank'
        href={url}
        rel='noreferrer'
        variant='primary'
        style={{ marginRight: 'auto' }}
      >
        <span className='fa fa-refresh' style={{ marginRight: '5px' }} />
        Deduplicate
      </Button>
    );
  }

  renderButtons() {
    return (
      <>
        {this.renderDedupLink()}
        {this.renderCancel()}
        {this.renderConfirm()}
      </>
    );
  }

  getAppNumber() {
    let appNumber = this.props.matter.get('applicationNumber');
    if (/[0-9]{8}/.test(appNumber)) {
      appNumber = `${appNumber.substr(0, 2)}/${appNumber.substr(2, 3)},${appNumber.substr(5, 3)}`;
    }
    return appNumber ? `(${appNumber})` : '';
  }

  render() {
    return (
      <Modal
        keyboard={false}
        size='xl'
        backdrop='static'
        show={this.state.show}
        onHide={this.onClickCancel}
      >
        <Modal.Header closeButton>
          <Modal.Title>
          Batch Sweep {this.getAppNumber()}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.renderBody()}
        </Modal.Body>
        <Modal.Footer>
          {this.renderButtons()}
        </Modal.Footer>
      </Modal>
    );
  }
}
