import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import DropdownHelp from '../shared/DropdownHelp';
import CheckButton from '../shared/CheckButton';
import MenuItemHelp from '../shared/MenuItemHelp';
import Immutable from 'immutable';
import { isRecentMonths } from '../../utils/date';

export default class PtoFreeStatement extends Component {
  static propTypes = {
    matter: PropTypes.instanceOf(Immutable.Map).isRequired,
    documents: PropTypes.instanceOf(Immutable.Map).isRequired,
    updateMatter: PropTypes.func.isRequired,
    fetchDocumentsIfNeeded: PropTypes.func.isRequired
  };

  static getDerivedStateFromProps(props, state) {
    if (!Immutable.is(props.matter, state.matter)) {
      return {
        matter: props.matter,
        hasOldUncited: false,
        loadingDocs: false,
        statement: props.matter.get('statement'),
        checked: Boolean(props.matter.get('within30Days'))
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {};
  }

  async checkUncitedDocuments() {
    const matter = this.props.matter;

    await this.props.fetchDocumentsIfNeeded(matter);

    const docNumbers = matter.get('uncitedArt', Immutable.List());

    return docNumbers.map(docNumber => this.props.documents.get(docNumber))
      .some(doc => !isRecentMonths(doc.get('createdTime'), 3));
  }

  async onOpen() {
    this.setState({
      loadingDocs: true
    });

    const hasOldUncited = await this.checkUncitedDocuments();

    this.setState({
      hasOldUncited,
      loadingDocs: false
    });
  }

  onChange(statement) {
    if (statement === 'within30Days') {
      this.onChange30Days(!this.state.checked);
      return;
    }

    this.setState({ statement });

    const updateMatter = {
      id: this.props.matter.get('id'),
      statement
    };
    if (statement === '2') {
      updateMatter.within30Days = false;
    }
    this.props.updateMatter(updateMatter);
  }

  is30DaysEnabled() {
    return this.props.matter.get('statement') === '0' || this.props.matter.get('statement') === '1';
  }

  onChange30Days(checked) {
    if (checked && !this.is30DaysEnabled()) {
      this.onChange30Days(false);
      return;
    }

    this.setState({ checked });
    this.props.updateMatter({
      id: this.props.matter.get('id'),
      within30Days: checked
    }, this.props.matter.get('matterNumber'));
  }

  render30Days() {
    return (
      <div className='pull-left'>
        <CheckButton
          disabled={!this.is30DaysEnabled()}
          stopPropagation={false}
          style={{
            position: 'relative',
            left: '0px',
            top: '-2px',
            width: '20px'
          }}
          className='pull-left'
          checked={this.props.matter.get('statement') === '2' ? false : this.state.checked}
          onChange={this.onChange30Days}
        />
        &lt; 30 Days
      </div>
    );
  }

  renderFooter() {
    return (
      <MenuItemHelp
        id='statement-30-days'
        styleLink={{
          height: '30px',
          backgroundColor: 'transparent'
        }}
        disabled={!this.is30DaysEnabled()}
        help='704(d) All items cited in a foreign counterpart < 30 days ago'
        label={this.render30Days()}
        eventKey='within30Days'
      />
    );
  }

  getOptions() {
    const statementOptions = [{
      value: '0',
      label: '§1.97(e)(1)',
      help: 'All items first cited in a foreign counterpart < 3 months ago',
      labelClass: this.state.hasOldUncited ? 'text-danger' : null,
      helpIcon: this.state.loadingDocs ? 'fa-spinner fa-spin' : undefined
    }, {
      value: '1',
      label: '§1.97(e)(2)',
      help: 'All items known < 3 months ago',
      labelClass: this.state.hasOldUncited ? 'text-danger' : null,
      helpIcon: this.state.loadingDocs ? 'fa-spinner fa-spin' : undefined
    }];

    if (this.props.matter.get('timing') !== '2') {
      statementOptions.push({
        value: '2',
        label: 'None',
        help: this.props.matter.get('timing') === '3'
          ? 'Neither of the above apply'
          : 'Neither of the above apply (so pay the fee)'
      });
    }

    return statementOptions;
  }

  render() {
    if (this.props.matter.get('timing') === '0') {
      return (
        <span>None</span>
      );
    }

    return (
      <DropdownHelp
        id={`dropdown-pto-fee-statement-${this.props.matter.get('id')}`}
        delayValues={['within30Days']}
        options={this.getOptions()}
        selected={this.state.statement}
        emptyLabel='Statement'
        footer={this.renderFooter()}
        onOpen={this.onOpen}
        onChange={this.onChange}
      />
    );
  }
};
