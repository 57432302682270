import { connect } from 'react-redux';
import actions from '../../redux/actions';
import PtoFee from './PtoFee';
import Immutable from 'immutable';

function mapStateToProps(state) {
  const {
    app: {
      selectedMatterId,
      selectedClientId,
      expandedPtoFeePanel,
      features
    },
    entities
  } = state;
  const client = entities.getIn(['clients', selectedClientId]) || Immutable.Map();
  const matter = entities.getIn(['matters', selectedMatterId]) || Immutable.Map();
  const documents = entities.getIn(['documents', matter.get('clientNumber')], Immutable.Map());

  return {
    expanded: expandedPtoFeePanel,
    features,
    client,
    matter,
    documents
  };
};

export default connect(mapStateToProps, actions)(PtoFee);
