import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import Immutable from 'immutable';

export default class PtoFeeInput extends Component {
  static propTypes = {
    matter: PropTypes.instanceOf(Immutable.Map).isRequired,
    field: PropTypes.string.isRequired,
    updateMatter: PropTypes.func.isRequired
  };

  static getDerivedStateFromProps(props, state) {
    if (!Immutable.is(props.matter, state.matter)) {
      return {
        matter: props.matter,
        value: props.matter.get(props.field) || ''
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {};
  }

  onChange(e, value = e.target.value) {
    this.setState({ value });
  }

  raiseChange() {
    const { value } = this.state;
    const { matter, field } = this.props;
    const currentValue = matter.get(field) || '';
    if (currentValue !== value) {
      this.props.updateMatter({
        id: matter.get('id'),
        [field]: value
      }, matter.get('matterNumber'));
    }
  }

  onBlur(e) {
    const value = e.target.value;

    this.setState({
      value
    }, () => {
      this.raiseChange();
    });
  }

  onKeyDown(e) {
    if (e.which === 13) {
      this.onBlur(e);
    }
  }

  render() {
    return (
      <div
        className='form-group'
        style={{
          marginBottom: 0,
          backgroundColor: 'white',
          borderRadius: '6px',
          position: 'relative',
          width: '120px'
        }}
      >
        <input
          type='number'
          lang='en'
          spellCheck={false}
          name={this.props.field}
          value={this.state.value || ''}
          className='form-control input-sm'
          onChange={this.onChange}
          onBlur={this.onBlur}
          onKeyDown={this.onKeyDown}
        />
      </div>
    );
  }
}
