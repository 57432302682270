import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import Immutable from 'immutable';
import { API_ROOT } from '../../redux/constants/urls';
import TodoRecord from '../../models/TodoRecord';
import { Modal, Button } from 'react-bootstrap';
import HelpIcon from '../shared/HelpIcon';
import Loading from '../shared/Loading';
import { downloadUrl } from '../../utils/download-url';
import AlertModal from '../shared/AlertModal';

export default class IDSModal extends Component {
  static propTypes = {
    features: PropTypes.object.isRequired,
    visibleDocuments: PropTypes.instanceOf(Immutable.List).isRequired,
    todo: PropTypes.instanceOf(TodoRecord).isRequired,
    onUpload: PropTypes.func.isRequired,
    showUpload: PropTypes.bool.isRequired,
    setFileReminder: PropTypes.func.isRequired,
    subsetIDSEnabled: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    callApi: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    autoBind(this);

    this.incrementalId = 0;

    this.state = {
      subsetIDS: false,
      includeNPL: true,
      includeForeign: true,
      excludeLetter: false,
      skipFix: false,
      formType: window.localStorage.getItem('ids_form_type') || 'pto',
      promptSizeFee: null
    };
  }

  addFailed(failed) {
    return new Promise(resolve => {
      this.setState({
        failed: (this.state.failed || []).concat(failed)
      }, resolve);
    });
  }

  addFiles(files) {
    return new Promise(resolve => {
      this.setState({
        files: this.state.files.concat(files)
      }, resolve);
    });
  }

  createProgress(item) {
    return {
      id: ++this.incrementalId,
      ...item
    };
  }

  removeProgress(item) {
    item.removed = true;

    return new Promise(resolve => {
      this.setState({
        progress: (this.state.progress || []).filter(progress => progress.id !== item.id && !progress.removed)
      }, resolve);
    });
  }

  addProgress(item) {
    return new Promise(resolve => {
      this.setState({
        progress: (this.state.progress || []).filter(progress => !progress.removed).concat(item)
      }, resolve);
    });
  }

  makeRequest(url, data) {
    return this.props.callApi({ url, data, method: 'POST', noQuery: true }, null, true);
  }

  async startPackage() {
    this.setState({
      generated: null,
      files: [],
      started: Date.now()
    });

    const progress = this.createProgress({ step: 'generate-start' });

    await this.addProgress(progress);

    try {
      const { docs } = await this.makeRequest(this.getURL('generate-start'));
      await this.removeProgress(progress);
      return docs;
    } catch (error) {
      await this.addFailed({ step: 'generate-start', error });
      await this.removeProgress(progress);
    }
  }

  async generateForms() {
    const progress = this.createProgress({ step: 'generate-forms' });

    await this.addProgress(progress);

    try {
      const { forms } = await this.makeRequest(this.getURL('generate-forms'));
      await this.addFiles(forms);
    } catch (error) {
      await this.addFailed({ step: 'generate-forms', error });
    }

    await this.removeProgress(progress);
  }

  async finalizePackage() {
    const progress = this.createProgress({ step: 'generate-package' });

    await this.addProgress(progress);

    try {
      const data = await this.makeRequest(this.getURL('generate-package'), { files: this.state.files });
      await this.removeProgress(progress);
      return data;
    } catch (error) {
      await this.addFailed({ step: 'generate-package', error });
      await this.removeProgress(progress);
    }
  }

  async generateDocument(doc, index, total) {
    const progress = this.createProgress({ step: 'generate-document', doc, index, total });

    await this.addProgress(progress);

    const url = this.getURL('generate-document', { documentNumber: doc.documentNumber });
    try {
      const { files } = await this.makeRequest(url);
      await this.addFiles(files);
    } catch (error) {
      await this.addFailed({ step: 'generate-document', doc, error });
    }

    await this.removeProgress(progress);
  }

  async generateDocuments(docs) {
    const total = docs.length;

    const docsToProcess = docs.map((doc, index) => ({ doc, index, total }));

    const processNextDoc = async() => {
      if (!docsToProcess.length) {
        return;
      }

      const { doc, index, total } = docsToProcess.shift();
      await this.generateDocument(doc, index, total);
      await processNextDoc();
    };

    await Promise.all([
      processNextDoc(),
      processNextDoc(),
      processNextDoc()
    ]);
  }

  async generatePackage() {
    const docs = await this.startPackage();

    if (docs) {
      await this.generateDocuments(docs);
    }

    await this.generateForms();

    const pkg = await this.finalizePackage();

    if (pkg) {
      this.setState({
        finished: Date.now(),
        progress: null,
        generated: pkg
      });

      return pkg;
    }
  }

  resetPackageState() {
    return new Promise(resolve => {
      this.setState({
        progress: null,
        finished: null,
        generated: null,
        failed: null,
        files: null,
        started: null,
        copied: null
      }, resolve);
    });
  }

  assignRefCopyLink(input) {
    if (input) {
      input.focus({ preventScroll: true });
      input.select();
      document.execCommand('copy');
    }
  }

  copyUrl() {
    const input = document.getElementById(`copy-${this.props.todo.id}`);
    this.assignRefCopyLink(input);
    this.setState({ copied: true });
    this.props.setFileReminder(this.props.todo, true);
  }

  downloadPackage(url) {
    downloadUrl(url);
    this.props.setFileReminder(this.props.todo, true);
  }

  selectFormType(formType) {
    window.localStorage.setItem('ids_form_type', formType);
    this.setState({ formType });
  }

  onClickFormSyncIDS() {
    this.selectFormType('syncids');
  }

  onClickFormPTO() {
    this.selectFormType('pto');
  }

  onClose() {
    this.props.onClose();
  }

  onClickSkipFix() {
    this.setState({
      skipFix: !this.state.skipFix
    });
  }

  onClickIncludeNPL() {
    this.setState({
      includeNPL: !this.state.includeNPL
    });
  }

  onClickIncludeForeign() {
    this.setState({
      includeForeign: !this.state.includeForeign
    });
  }

  onClickExcludeLetter() {
    this.setState({
      excludeLetter: !this.state.excludeLetter
    });
  }

  onClickSubsetIDS() {
    this.setState({
      subsetIDS: !this.state.subsetIDS
    });
  }

  onConfirmPromptSizeFee() {
    const promptSizeFee = this.state.promptSizeFee;

    this.setState({ promptSizeFee: false }, () => {
      if (promptSizeFee === 'download') {
        this.onClickDownload();
      } else if (promptSizeFee === 'copy') {
        this.onClickCopy();
      }
    });
  }

  async onClickDownload() {
    if (this.isDisabled()) {
      return;
    }

    if (this.state.formType === 'pto' && this.state.promptSizeFee === null) {
      this.setState({ promptSizeFee: 'download' });
      return;
    }

    if (this.state.generated) {
      await this.resetPackageState();
    }

    const pkg = await this.generatePackage();

    this.downloadPackage(pkg.url);
  }

  async onClickUpload() {
    if (this.isDisabled()) {
      return;
    }

    if (this.state.generated) {
      await this.resetPackageState();
    }

    const pkg = await this.generatePackage();

    this.props.onUpload(this.props.todo, pkg.url);
  }

  async onClickCopy() {
    if (this.isDisabled()) {
      return;
    }

    if (this.state.formType === 'pto' && this.state.promptSizeFee === null) {
      this.setState({ promptSizeFee: 'copy' });
      return;
    }

    if (this.state.generated) {
      this.copyUrl();
      return;
    }

    await this.resetPackageState();
    await this.generatePackage();
    this.copyUrl();
  }

  renderOption({ onClick, checked, label, name, type, disabled, help }) {
    return (
      <div style={{ margin: '5px 0' }} className={disabled ? 'text-muted' : ''}>
        <label htmlFor={name}>
          <input
            disabled={disabled}
            onChange={onClick}
            checked={checked}
            style={{ marginRight: '7px' }}
            name={name}
            type={type}
          />
          <span>{label}</span>
          {help && (
            <HelpIcon
              style={{ marginLeft: '5px' }}
              help={help}
            />
          )}
        </label>
      </div>
    );
  }

  renderNotesSearch() {
    return this.renderOption({
      disabled: !this.props.subsetIDSEnabled,
      checked: this.props.subsetIDSEnabled && this.state.subsetIDS,
      onClick: this.onClickSubsetIDS,
      name: 'subset-ids',
      label: 'Reduce IDS to displayed documents only',
      type: 'checkbox',
      help: this.props.subsetIDSEnabled
        ? `Check this box to generate an IDS using only the currently selected ${this.props.visibleDocuments.size} uncited documents`
        : 'You may filter your document view using the "Search notes" feature in the Documents title bar'
    });
  }

  renderSkipFix() {
    return this.renderOption({
      checked: this.state.skipFix,
      onClick: this.onClickSkipFix,
      name: 'skipFix',
      label: 'Skip PDF fixing (faster)',
      type: 'checkbox'
    });
  }

  renderIncludeForeign() {
    return this.renderOption({
      checked: this.state.includeForeign,
      onClick: this.onClickIncludeForeign,
      name: 'include-foreign-pdf',
      label: 'Include PDFs of foreign references',
      type: 'checkbox'
    });
  }

  renderIncludeNPL() {
    return this.renderOption({
      checked: this.state.includeNPL,
      onClick: this.onClickIncludeNPL,
      name: 'include-npl-pdf',
      label: 'Include PDFs of NPL references',
      type: 'checkbox'
    });
  }

  renderExcludeLetter() {
    return this.renderOption({
      checked: this.state.excludeLetter,
      onClick: this.onClickExcludeLetter,
      name: 'exclude-letter',
      label: 'Exclude Transmittal Letter',
      type: 'checkbox'
    });
  }

  renderReminder() {
    return (
      <div>
        <span className='fa fa-exclamation-triangle text-danger' style={{ marginRight: '5px' }} />
        <span>
          After this IDS is filed, you must click the "<span className='text-danger'>Cited?</span>" button to update the database.
        </span>
      </div>
    );
  }

  getURL(type, moreParams = {}) {
    const params = new URLSearchParams({
      type: this.state.formType === 'syncids' ? 'SyncIDS' : 'PTO_IDS',
      timezoneOffset: new Date().getTimezoneOffset()
    });

    if (!this.state.skipFix) {
      params.set('unembedFonts', 'unembedFonts');
      params.set('orientation', 'orientation');
      params.set('fitPages', 'fitPages');
      params.set('fixVersion', 'fixVersion');
    }

    if (!this.state.includeNPL && !this.state.includeForeign) {
      params.set('noReferences', 'noReferences');
    } else if (!this.state.includeNPL) {
      params.set('noNPL', 'noNPL');
    } else if (!this.state.includeForeign) {
      params.set('noForeign', 'noForeign');
    }

    if (this.state.excludeLetter) {
      params.set('noLetter', 'noLetter');
    }

    if (this.state.subsetIDS) {
      const docNumbers = this.props.visibleDocuments.map(doc => doc.getRealDocumentNumber()).toJS();
      params.set('documentNumbers', docNumbers.join(','));
    }

    Object.keys(moreParams).forEach(key => {
      params.set(key, moreParams[key]);
    });

    const host = API_ROOT || window.location.origin;
    const baseUrl = `${host}/api/ids/${this.props.todo.id}`;

    return `${baseUrl}/${type}?${params.toString()}`;
  }

  isDisabled() {
    return Boolean(this.state.progress || !this.props.todo.canGenerateIds());
  }

  renderCancelButton() {
    return (
      <Button
        variant='secondary'
        style={{ width: '132px' }}
        onClick={this.onClose}
      >
        {this.state.generated ? 'Close' : 'Cancel'}
      </Button>
    );
  }

  renderUploadButton() {
    return (
      <Button
        variant='primary'
        disabled={this.isDisabled()}
        style={{ width: '132px', marginLeft: '9px' }}
        onClick={this.onClickUpload}
      >
        Auto Upload
      </Button>
    );
  }

  renderCopyButton() {
    return (
      <Button
        variant='primary'
        disabled={this.isDisabled()}
        style={{ width: '132px', marginLeft: '9px' }}
        onClick={this.onClickCopy}
      >
        Copy Link
      </Button>
    );
  }

  renderDownloadButton() {
    return (
      <Button
        variant='primary'
        disabled={this.isDisabled()}
        style={{ width: '132px', marginLeft: '9px' }}
        onClick={this.onClickDownload}
      >
        Download
      </Button>
    );
  }

  renderMoreOptions() {
    return (
      <div className='col-md-8'>
        <div style={{ marginBottom: '10px' }}>
          <strong>Options</strong>
        </div>
        {this.renderIncludeForeign()}
        {this.renderIncludeNPL()}
        {this.renderExcludeLetter()}
        {this.renderNotesSearch()}
        {this.renderSkipFix()}
      </div>
    );
  }

  renderOptions() {
    return (
      <div className='row' style={{ clear: 'both' }}>
        <div className='col-md-4'>
          <div style={{ marginBottom: '10px' }}>
            <strong>Form</strong>
          </div>
          {this.renderOption({
            checked: this.state.formType === 'pto',
            onClick: this.onClickFormPTO,
            name: 'form-type',
            label: 'Use PTO IDS',
            type: 'radio'
          })}
          {this.renderOption({
            checked: this.state.formType === 'syncids',
            onClick: this.onClickFormSyncIDS,
            name: 'form-type',
            label: 'Use SyncIDS IDS',
            type: 'radio'
          })}
        </div>
        {this.props.features.limitedDocs ? null : this.renderMoreOptions()}
      </div>
    );
  }

  renderProgressItem(progress, index) {
    return (
      <Loading
        key={index}
        text={this.getProgressText(progress)}
      />
    );
  }

  onFocusCopyLink(e) {
    e.target.select();
  }

  renderProgress() {
    if (!this.state.progress && !this.state.generated) {
      return;
    }

    return (
      <Modal.Footer style={{ justifyContent: 'flex-start' }}>
        <div className='text-left' style={{ flex: 1 }}>
          {this.state.progress
            ? this.state.progress.filter(progress => !progress.removed).map(this.renderProgressItem)
            : this.getFinishedText()}
          {this.renderFailed()}
          {this.state.copied && (
            <div style={{ marginTop: '10px' }}>
              Copy link:
              <input
                type='text'
                readOnly
                ref={this.assignRefCopyLink}
                onFocus={this.onFocusCopyLink}
                onClick={this.onFocusCopyLink}
                className='form-control'
                style={{ marginTop: '5px' }}
                value={this.state.generated.url}
              />
            </div>
          )}
        </div>
      </Modal.Footer>
    );
  }

  getDisplayDocNumber(doc) {
    const { documentNumber, type, country } = doc;
    return type === 'Foreign' ? `${country}${documentNumber.substr(1)}` : documentNumber;
  }

  getFinishedText() {
    const size = this.state.generated.size / 1024 / 1024;

    const seconds = (this.state.finished - this.state.started) / 1000;
    const minutes = Math.round(seconds / 60);
    const elapsed = minutes > 0 ? `${minutes}min` : `${Math.round(seconds)}sec`;

    return `Package generated in ${elapsed} with ${size.toFixed(1)} MB and ${this.state.generated.files.length} files.`;
  }

  getProgressText(progress) {
    switch (progress.step) {
      case 'generate-start':
        return 'Starting package...';
      case 'generate-forms':
        return 'Generating forms...';
      case 'generate-document':
        return `Generating document ${this.getDisplayDocNumber(progress.doc)} (${progress.index + 1} / ${progress.total})`;
      case 'generate-package':
        return 'Finalizing package...';
    }
  }

  getFailureText(item) {
    switch (item.step) {
      case 'generate-start':
        return 'Failed to start package.';
      case 'generate-forms':
        return 'Failed to generate forms.';
      case 'generate-document':
        if (item.error.response && item.error.response.data.includes('Missing pages')) {
          return `Missing pages in document ${this.getDisplayDocNumber(item.doc)}`;
        } else {
          return `Failed to generate document ${this.getDisplayDocNumber(item.doc)}`;
        }
      case 'generate-package':
        return 'Failed to finalize package.';
    }
  }

  renderFailedItem(item, index) {
    return (
      <li key={index}>
        {this.getFailureText(item)}
      </li>
    );
  }

  renderFailed() {
    if (!this.state.failed || !this.state.failed.length) {
      return;
    }

    return (
      <ul>
        {this.state.failed.map(this.renderFailedItem)}
      </ul>
    );
  }

  renderFooter() {
    return (
      <div>
        {this.renderCancelButton()}
        {this.props.showUpload && this.renderUploadButton()}
        {this.renderCopyButton()}
        {this.renderDownloadButton()}
      </div>
    );
  }

  renderCopyInput() {
    return this.state.generated && (
      <input
        type='text'
        id={`copy-${this.props.todo.id}`}
        readOnly
        value={this.state.generated.url}
        style={{
          position: 'absolute',
          zIndex: -1,
          width: '10px',
          outline: 'none'
        }}
      />
    );
  }

  renderTimingWarning() {
    return (
      <div style={{ marginTop: '15px' }}>
        <span className='fa fa-bell text-warning' />
        <span style={{ marginLeft: '10px' }}>
          Reminder. Set Timing and Statement before proceeding.
        </span>
      </div>
    );
  }

  renderPromptSizeFee() {
    return (
      <AlertModal
        onConfirm={this.onConfirmPromptSizeFee}
        focusConfirm
        title='IDS SIZE PTO FEE Assertion'
        message='PTO IDS Form (PTO/SB/08a) requires users to manually check the IDS Size Fee Assertion box before submission. Alternatively, users can rely on the "IDS Size Fee - Written Assertion Under 37 CFR 1.98" document for their assertion.'
      />
    );
  }

  render() {
    if (this.state.promptSizeFee) {
      return this.renderPromptSizeFee();
    }

    return (
      <Modal
        show
        size='lg'
        onHide={this.onClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>
          Download IDS Package
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.renderOptions()}
          {this.renderCopyInput()}
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: 'flex-start' }}>
          {this.renderReminder()}
          {this.renderTimingWarning()}
        </Modal.Footer>
        {this.renderProgress()}
        <Modal.Footer>
          {this.renderFooter()}
        </Modal.Footer>
      </Modal>
    );
  }
};
