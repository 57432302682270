import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import DropdownHelp from '../shared/DropdownHelp';
import Immutable from 'immutable';

const timingOptions = [{
  value: '0',
  label: '§1.97(b)',
  help: 'Before the first Office action after a new filing or an RCE, or < 3 months after filing non-CPA national or entering national'
}, {
  value: '1',
  label: '§1.97(c)',
  help: 'Before the final Office action or the notice of allowance'
}, {
  value: '2',
  label: '§1.97(d)',
  help: 'On or before payment of the issue fee'
}, {
  value: '3',
  label: '§1.97(i)',
  help: 'After payment of the issue fee without QPIDS. Will be placed in the file but will not be considered by the Office.'
}];

export default class PtoFeeTiming extends Component {
  static propTypes = {
    matter: PropTypes.instanceOf(Immutable.Map).isRequired,
    updateMatter: PropTypes.func.isRequired
  };

  static getDerivedStateFromProps(props, state) {
    if (!Immutable.is(props.matter, state.matter)) {
      return {
        matter: props.matter,
        timing: props.matter.get('timing')
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {};
  }

  onChange(timing) {
    this.setState({ timing });

    const updatedMatter = {
      id: this.props.matter.get('id'),
      timing
    };
    // update statement too, if necessary
    if (timing === '0' || (timing === '2' && this.props.matter.get('statement') === '2')) {
      updatedMatter.statement = '0';
    }
    if (timing === '0') {
      updatedMatter.within30Days = false;
    }
    this.props.updateMatter(updatedMatter);
  }

  render() {
    return (
      <DropdownHelp
        id={`dropdown-pto-fee-timing-${this.props.matter.get('id')}`}
        emptyLabel='Timing'
        options={timingOptions}
        selected={this.state.timing}
        onChange={this.onChange}
      />
    );
  }
};
