import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import CollapsiblePanel from '../../shared/CollapsiblePanel';
import { Button, Table } from 'react-bootstrap';
import { formatDate } from '../../../utils/date';
import BatchInputOptions from '../../documents/BatchInputOptions';

const styleTextarea = {
  resize: 'none',
  width: '100%'
};

const matterCols = [
  'clientNumber',
  'matterNumber',
  'applicationNumber',
  'firstInventor',
  'filingDate',
  'artUnit',
  'matterClosed',
  'confirmationNumber',
  'examinerName'
];

export default class BatchInput extends Component {
  static propTypes = {
    error: PropTypes.string,
    saveBatchInput: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      value: '',
      data: null,
      loading: false,
      values: {
        cited: true,
        citedOnIDS: false,
        citedOn892: false
      }
    };
  }

  isValidMatter(matter) {
    return (
      /\w/.test(matter.clientNumber) &&
      /\w/.test(matter.matterNumber)
    );
  }

  hasProcessedMatters() {
    return this.state.matters && this.state.matters.length;
  }

  isConfirmEnabled() {
    return (
      this.state.matters &&
      this.state.matters.length > 0 &&
      this.state.matters.every(matter => (
        this.isValidMatter(matter) &&
        matter.docs.every(doc => doc.valid)
      ))
    );
  }

  onClickConfirm() {
    this.setState({
      loading: true
    });
    this.props.saveBatchInput(this.state.matters, this.state.values)
      .finally(() => this.onClickClear());
  }

  onClickClear() {
    this.setState({
      value: '',
      matters: null,
      loading: false,
      values: {
        cited: true,
        citedOnIDS: false,
        citedOn892: false
      }
    });
  }

  onChange(e) {
    this.setState({
      value: e.target.value
    });
  }

  onClickProcess() {
    let reading = null;
    let currentMatter;
    const matters = [];

    this.state.value.trim().split(/\n/).forEach((line, index) => {
      if (/^\*/.test(line)) {
        return;
      }

      if (/^M$/i.test(line)) {
        reading = 'MATTER';
        return;
      }

      if (/^U\.?$/i.test(line)) {
        reading = 'US';
        return;
      }

      if (/^N\.?$/i.test(line)) {
        reading = 'NPL';
        return;
      }

      if (/^F\.?$/i.test(line)) {
        reading = 'FOREIGN';
        return;
      }

      if (/^(MATTER|US|FOREIGN|NPL)$/.test(line)) {
        reading = line;
        return;
      }

      if (!/\S/.test(line)) {
        return;
      }

      if (reading !== 'MATTER' && !currentMatter) {
        return;
      }

      switch (reading) {
        case 'MATTER': {
          currentMatter = { docs: [] };
          const vals = line.split(/[\t\;]/);
          matterCols.forEach((col, idx) => {
            let value = vals[idx];
            if (col === 'filingDate') {
              value = formatDate(value);
            }
            currentMatter[col] = value;
          });
          matters.push(currentMatter);
          break;
        }
        case 'US': {
          const number = line.trim()
            .replace(/A1$|A2$|B1$|B2$|S1$|S2$/, '')
            .replace(/[^0-9DRE]/g, '');
          currentMatter.docs.push({
            valid: [7, 8, 10, 11].includes(number.length),
            type: 'US',
            documentNumber: number
          });
          break;
        }
        case 'FOREIGN': {
          const vals = line
            .replace(/[-|\/|\(|\)]/g, '')
            .replace('W0', 'WO')
            .split(/[,\s\;]/);
          let country = vals.filter(v => /^[A-Z]{2}$/.test(v)).shift() || '';
          let kind = vals.filter(v => /^[AB]1?$/.test(v)).shift() || '';
          let number = vals.filter(val => val !== country && val !== kind).join('');

          if (/^[A-Z]{2}/.test(number)) {
            country = number.substr(0, 2);
            number = number.substr(2);
          }
          if (country && number.indexOf(country) !== -1) {
            country = '';
          }
          if (kind && number.indexOf(kind) !== -1) {
            kind = '';
          }

          currentMatter.docs.push({
            valid: Boolean(number && country && number.length < 30),
            type: 'Foreign',
            documentNumber: `0${country}${number}${kind}`
          });
          break;
        }
        case 'NPL': {
          const description = line.trim();
          currentMatter.docs.push({
            valid: /\w/.test(description),
            type: 'Other',
            nonPatentBib: description
          });
          break;
        }
        default:
          break;
      }
    });

    this.setState({
      matters
    });
  }

  renderForm() {
    return (
      <div style={{ padding: '20px 10px' }}>
        <textarea
          autoFocus
          className='form-control input-sm'
          rows={10}
          cols={50}
          value={this.state.value}
          onChange={this.onChange}
          spellCheck={false}
          style={styleTextarea}
        />
      </div>
    );
  }

  renderMatter(matter, index) {
    return (
      <Table striped bordered hover>
        <thead>
          <tr>
            {matterCols.map((col) => (
              <th key={col}>{col}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr key={index}>
            {matterCols.map((col) => <td key={col} style={{ whiteSpace: 'nowrap' }}>{matter[col] || '-'}</td>)}
          </tr>
        </tbody>
      </Table>
    );
  }

  renderDocs(docs) {
    return (
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Type</th>
            <th>Number</th>
            <th>Valid</th>
          </tr>
        </thead>
        <tbody>
          {docs.map((doc, docIndex) => {
            return (
              <tr key={docIndex}>
                <td>{doc.type}</td>
                <td>
                  {doc.documentNumber || doc.nonPatentBib}
                </td>
                <td>
                  <span className={doc.valid ? 'fa fa-check text-success' : 'fa fa-times text-danger'} />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  }

  renderMatters() {
    return (
      <div style={{ maxHeight: '600px', overflow: 'auto', marginBottom: '20px' }}>
        {this.state.matters.map((matter, matterIndex) => {
          return (
            <div key={matterIndex}>
              <h2>
                Matter {matterIndex + 1}
                &nbsp;
                <span className={this.isValidMatter(matter) ? 'fa fa-check text-success' : 'fa fa-times text-danger'} />
              </h2>
              {this.renderMatter(matter, matterIndex)}
              {this.renderDocs(matter.docs)}
            </div>
          );
        })}
      </div>
    );
  }

  renderBody() {
    if (this.state.matters && this.state.matters.length) {
      return this.renderMatters();
    }

    return this.renderInput();
  }

  renderInput() {
    return (
      <div className='row'>
        <div className='col-md-12'>
          {this.renderForm()}
        </div>
      </div>
    );
  }

  renderProcess() {
    return (
      <Button style={{ float: 'left' }} onClick={this.onClickProcess}>
        Process
      </Button>
    );
  }

  renderError() {
    return this.props.error && (
      <span style={{ marginRight: '15px' }}>{this.props.error}</span>
    );
  }

  renderConfirm() {
    return (
      <Button
        variant='primary'
        onClick={this.onClickConfirm}
        disabled={!this.isConfirmEnabled()}
      >
        Update
      </Button>
    );
  }

  renderSubmit() {
    return (
      <div style={{ textAlign: 'right' }}>
        {this.renderError()}
        <Button variant='secondary' onClick={this.onClickClear} style={{ marginRight: '15px' }}>
          Reset
        </Button>
        {this.renderConfirm()}
      </div>
    );
  }

  renderLoading() {
    return (
      <div style={{ textAlign: 'right' }}>
        <Button
          disabled
          variant='primary'
        >
        Processing...
        </Button>
      </div>
    );
  }

  onChangeOptions(values) {
    this.setState({
      values: { ...this.state.values, ...values }
    });
  }

  renderOptions() {
    return (
      <BatchInputOptions
        values={this.state.values}
        onChange={this.onChangeOptions}
      />
    );
  }

  renderFooter() {
    return (
      <div className='row'>
        <div className='col-md-6'>
          {this.hasProcessedMatters() ? this.renderOptions() : this.renderProcess()}
        </div>
        <div className='col-md-6'>
          {this.state.loading ? this.renderLoading() : this.renderSubmit()}
        </div>
      </div>
    );
  }

  render() {
    return (
      <CollapsiblePanel id='batch-input' header='Batch Input'>
        {this.renderBody()}
        {this.renderFooter()}
      </CollapsiblePanel>
    );
  }
}
