import { getPtoTimingFee } from './get-pto-timing-fee';
import Immutable from 'immutable';

export function getPtoFeeData(matter, client) {
  const ptoTimingFee = getPtoTimingFee(matter, client);
  const previouslyPaidAmount = matter.get('previouslyPaidAmount') || 0;
  const uncitedCount = matter.get('uncitedArt', Immutable.List()).size;
  const citedArtCount = matter.get('citedArtCount') || 0;
  const totalCumulativeAmount = getPtoTotalCumulativeFee(citedArtCount, uncitedCount);
  const finalFee = Math.max(0, totalCumulativeAmount.value - previouslyPaidAmount);

  return Immutable.fromJS({
    ptoTimingFee,
    uncitedCount,
    citedArtCount,
    totalCumulativeAmount,
    previouslyPaidAmount,
    ptoSizeFee: {
      label: `$${finalFee}`,
      value: finalFee
    }
  });
}

function getPtoTotalCumulativeFee(citedCount, uncitedCount) {
  const totalCited = getPtoSizeFee(citedCount);
  const totalWithUncited = getPtoSizeFee(citedCount + uncitedCount);

  if (totalWithUncited.value > totalCited.value) {
    return totalWithUncited;
  }

  return { label: 'n/a', value: 0 };
}

function getPtoSizeFee(total) {
  if (total <= 50) {
    return { label: '1-50 = $0', value: 0 };
  }

  if (total <= 100) {
    return { label: '51-100 = $200', value: 200 };
  }

  if (total <= 200) {
    return { label: '101-200 = $500', value: 500 };
  }

  return { label: '201+ = $800', value: 800 };
}
