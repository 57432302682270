import saveBatchMatterUpdate from './saveBatchMatterUpdate';
import chunk from 'lodash/chunk';
import callApi from '../../utils/callApi';
import addNotification from '../app/addNotification';
import { documentsUrl } from '../../constants/urls';

export default (mattersToUpdate, options) => async(dispatch) => {
  const matters = await dispatch(saveBatchMatterUpdate(mattersToUpdate, true));
  const docTypes = ['US', 'Foreign', 'Other'];

  for (const matter of matters) {
    for (const docType of docTypes) {
      const field = docType === 'Other' ? 'nonPatentBib' : 'documentNumber';
      const docsToAdd = matter.docs.filter(doc => doc.type === docType);
      const addedDocs = [];
      const failedDocs = [];

      const isNPL = docType === 'Other';
      const chunkSize = isNPL ? 1 : 50;
      const docChunks = chunk(docsToAdd, chunkSize);

      for (const docs of docChunks) {
        dispatch(addNotification({
          id: `add-docs-${matter.id}-${docType}`,
          type: 'progress',
          message: `Adding ${docType} docs (${addedDocs.length} / ${docsToAdd.length})...`
        }));

        try {
          await dispatch(callApi({
            url: documentsUrl + '/add-to-matter',
            params: {
              matterId: matter.id,
              documentNumber: docs.map(doc => doc[field]).join(';'),
              type: docType,
              isCited: options.cited,
              citedOnIDS: options.citedOnIDS,
              citedOn892: options.citedOn892
            }
          }));
          addedDocs.push(...docs);
        } catch (e) {
          if (isNPL) {
            failedDocs.push(...docs);

            dispatch(addNotification({
              id: `add-docs-${matter.id}-${docType}-${docType}`,
              type: 'error',
              message: `Failed ${docs[0][field]}`
            }, 0));
          } else {
            for (const doc of docs) {
              try {
                await dispatch(callApi({
                  url: documentsUrl + '/add-to-matter',
                  params: {
                    matterId: matter.id,
                    documentNumber: doc[field],
                    type: docType,
                    isCited: options.cited,
                    citedOnIDS: options.citedOnIDS,
                    citedOn892: options.citedOn892
                  }
                }));
                addedDocs.push(doc);
              } catch (e) {
                failedDocs.push(doc);

                dispatch(addNotification({
                  id: `add-docs-${matter.id}-${docType}-${doc[field]}`,
                  type: 'error',
                  message: `Failed ${doc[field]}`
                }, 0));
              }
            }
          }
        }
      }

      dispatch(addNotification({
        id: `add-docs-${matter.id}-${docType}`,
        type: 'success',
        message: `Done ${docType} docs (${addedDocs.length} / ${docsToAdd.length})...`
      }));
    }
  }
};
