import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import { Button } from 'react-bootstrap';
import NearDuplicateModal from '../near-duplicate/NearDuplicateModal';
import RemoveEquivalentsModal from './RemoveEquivalentsModal';
import UndeleteDocumentsModal from './UndeleteDocumentsModal';
import RemoveNonPriorArtModal from './RemoveNonPriorArtModal';
import HelpIcon from '../shared/HelpIcon';

export default class DocumentsTools extends Component {
  static propTypes = {
    onAction: PropTypes.func.isRequired,
    addDocumentToMatter: PropTypes.func.isRequired,
    clearNearDuplicate: PropTypes.func.isRequired,
    documents: PropTypes.object.isRequired,
    features: PropTypes.object.isRequired,
    fetchDocumentsIfNeeded: PropTypes.func.isRequired,
    fetchNearDuplicate: PropTypes.func.isRequired,
    getEquivalents: PropTypes.func.isRequired,
    loadMatterNotes: PropTypes.func.isRequired,
    matter: PropTypes.object.isRequired,
    matterNotes: PropTypes.array.isRequired,
    nearDuplicate: PropTypes.object.isRequired,
    removeEquivalents: PropTypes.func.isRequired,
    removeNonPriorArt: PropTypes.func.isRequired,
    replaceDocNumber: PropTypes.func.isRequired,
    sortDocuments: PropTypes.func.isRequired,
    updateDocument: PropTypes.func.isRequired,
    updateMatter: PropTypes.func.isRequired,
    logAction: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      filterType: 'notes',
      showNearDuplicate: window.location.hash.includes('showNearDuplicate=true')
    };
  }

  onClickNearDuplicate(e) {
    e.stopPropagation();
    this.setState({
      showNearDuplicate: true
    });
  }

  onClickUndeleteDocuments(e) {
    e.stopPropagation();
    this.setState({
      showUndeleteDocuments: true
    });
  }

  onClickRemoveEquivaments(e) {
    e.stopPropagation();
    this.setState({
      confirmRemoveEquivalents: true
    });
  }

  onCloseNearDuplicate() {
    this.props.clearNearDuplicate();
    this.setState({
      showNearDuplicate: false
    });
  }

  onClickSortDocuments(e) {
    e.stopPropagation();
    this.props.sortDocuments(this.props.matter.get('id'));
  }

  onCloseRemoveEquivalents() {
    this.setState({
      confirmRemoveEquivalents: false
    });
  }

  onClickRemoveNonPriorArt() {
    this.setState({
      confirmRemoveNonPriorArt: true
    });
  }

  onCloseRemoveNonPriorArt() {
    this.setState({
      confirmRemoveNonPriorArt: false
    });
  }

  onCloseUndeleteDocumentsModal() {
    this.setState({
      showUndeleteDocuments: false
    });
  }

  renderSort() {
    return (
      <Button
        style={{ height: '30px' }}
        onClick={this.onClickSortDocuments}
        variant='primary'
        size='sm'
      >
        <span className='fa fa-sort-amount-asc' style={{ marginRight: '5px' }} />
        Sort
      </Button>
    );
  }

  renderNearDuplicateButton() {
    return (
      <Button
        style={{ height: '30px' }}
        onClick={this.onClickNearDuplicate}
        variant='primary'
        size='sm'
      >
        <span className='fa fa-refresh' style={{ marginRight: '5px' }} />
        Deduplicate
      </Button>
    );
  }

  renderUndeleteDocumentsButton() {
    return (
      <Button
        style={{ height: '30px' }}
        onClick={this.onClickUndeleteDocuments}
        variant='primary'
        size='sm'
      >
        <span className='fa fa-recycle' style={{ marginRight: '5px' }} />
        Deleted List
        &nbsp;
        <HelpIcon
          id='undelete-help'
          placement='top'
          help='Deleted List includes a list of references that have been deleted from this matter. User may undelete any references by checking the box.'
        />
      </Button>
    );
  }

  renderRemoveEquivalentsButton() {
    return (
      <Button
        style={{ height: '30px' }}
        onClick={this.onClickRemoveEquivaments}
        variant='primary'
        size='sm'
      >
        <span className='fa fa-check-circle-o' style={{ marginRight: '5px' }} />
        Remove Equivalents
        &nbsp;
        <HelpIcon
          id='remove-equivaments-help'
          placement='top'
          help={(
            <>
              <strong>Remove Equivalents</strong> is an on-demand tool that deletes references that are considered equivalent to US patent numbers.  This helps ensure that only unique and non-equivalent references are included in your IDS.  This tool streamlines the IDS review process by minimizing the number of references patent practitioners and Examiners need to consider. Deleted references can be viewed by clicking the <strong>Delete List</strong> button in the Documents section.
            </>
          )}
        />
      </Button>
    );
  }

  renderRemoveNonPriorArtButton() {
    return (
      <Button
        style={{ height: '30px' }}
        onClick={this.onClickRemoveNonPriorArt}
        variant='primary'
        size='sm'
      >
        <span className='fa fa-check-circle-o' style={{ marginRight: '5px' }} />
        Remove Non-Prior Art
        &nbsp;
        <HelpIcon
          id='remove-non-prior-art-help'
          placement='top'
          help={(
            <>
              <strong>Remove Non-Prior Art</strong> is an on-demand tool that deletes uncited references from this matter where its priority date is AFTER this matter’s priority date. This tool streamlines the IDS review process by minimizing the number of references patent practitioners and Examiners need to consider. Deleted references can be viewed by clicking the <strong>Delete List</strong> button in the Documents section.
            </>
          )}
        />
      </Button>
    );
  }

  renderNearDuplicate() {
    return this.state.showNearDuplicate && (
      <NearDuplicateModal
        features={this.props.features}
        firmName={this.props.matter.get('firmName')}
        clientNumber={this.props.matter.get('clientNumber')}
        fetchNearDuplicate={this.props.fetchNearDuplicate}
        clearNearDuplicate={this.props.clearNearDuplicate}
        updateDocument={this.props.updateDocument}
        replaceDocNumber={this.props.replaceDocNumber}
        onClose={this.onCloseNearDuplicate}
        {...this.props.nearDuplicate}
      />
    );
  }

  renderConfirmRemoveEquivalents() {
    return this.state.confirmRemoveEquivalents && (
      <RemoveEquivalentsModal
        matter={this.props.matter}
        getEquivalents={this.props.getEquivalents}
        removeEquivalents={this.props.removeEquivalents}
        onClose={this.onCloseRemoveEquivalents}
      />
    );
  }

  renderUndeleteDocumentsModal() {
    return this.state.showUndeleteDocuments && (
      <UndeleteDocumentsModal
        features={this.props.features}
        loadMatterNotes={this.props.loadMatterNotes}
        matter={this.props.matter}
        matterNotes={this.props.matterNotes}
        documents={this.props.documents}
        addDocumentToMatter={this.props.addDocumentToMatter}
        fetchDocumentsIfNeeded={this.props.fetchDocumentsIfNeeded}
        logAction={this.props.logAction}
        onClose={this.onCloseUndeleteDocumentsModal}
        onAction={this.props.onAction}
      />
    );
  }

  renderRemoveNonPriorArtModal() {
    return this.state.confirmRemoveNonPriorArt && (
      <RemoveNonPriorArtModal
        matter={this.props.matter}
        documents={this.props.documents}
        onClose={this.onCloseRemoveNonPriorArt}
        updateMatter={this.props.updateMatter}
        removeNonPriorArt={this.props.removeNonPriorArt}
      />
    );
  }

  render() {
    return (
      <>
        {this.renderSort()}
        {this.renderNearDuplicateButton()}
        {this.renderUndeleteDocumentsButton()}
        {this.renderRemoveEquivalentsButton()}
        {this.renderRemoveNonPriorArtButton()}
        {this.renderNearDuplicate()}
        {this.renderConfirmRemoveEquivalents()}
        {this.renderUndeleteDocumentsModal()}
        {this.renderRemoveNonPriorArtModal()}
      </>
    );
  }
};
