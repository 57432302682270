import Immutable from 'immutable';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Table, Tabs, Tab } from 'react-bootstrap';
import autoBind from 'class-autobind';
import _uniq from 'lodash/uniq';
import DocumentRecord from '../../models/DocumentRecord';
import NotesHeader from './NotesHeader';
import Loading from '../shared/Loading';
import DocumentActions from './DocumentActions';

export default class UndeleteDocumentsModal extends Component {
  static propTypes = {
    matterNotes: PropTypes.array.isRequired,
    loadMatterNotes: PropTypes.func.isRequired,
    documents: PropTypes.instanceOf(Immutable.Map).isRequired,
    matter: PropTypes.instanceOf(Immutable.Map).isRequired,
    onClose: PropTypes.func.isRequired,
    fetchDocumentsIfNeeded: PropTypes.func.isRequired,
    addDocumentToMatter: PropTypes.func.isRequired,
    features: PropTypes.object.isRequired,
    onAction: PropTypes.func.isRequired,
    logAction: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      show: true,
      selected: [],
      type: 'US',
      saving: false,
      notes: 'notes',
      loading: false
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.props.fetchDocumentsIfNeeded(this.props.matter, true)
      .then(() => this.setState({ loading: false }));
  }

  onClose() {
    this.setState({ show: false });
    this.props.onClose();
  }

  onConfirm() {
    this.setState({ saving: true });
    let promise = Promise.resolve();
    this.getSelectedDocuments().forEach(doc => {
      promise = promise.then(() => this.props.addDocumentToMatter({
        matterId: this.props.matter.get('id'),
        documentNumber: doc.getRealDocumentNumber(),
        type: doc.get('type')
      }));
    });
    promise.then(() => {
      this.setState({ saving: false });
      this.props.onClose();
    });
  }

  getSelectedDocuments() {
    return this.props.matter.get('deletedArt')
      .map(docNumber => new DocumentRecord(this.props.documents.get(docNumber)))
      .filter(doc => doc && this.state.selected.includes(doc.get('documentNumber')));
  }

  getDocument(docNumber) {
    const doc = this.props.documents.get(docNumber);
    const matterNotes = this.props.matterNotes.find(note => note.documentNumber === docNumber);

    return new DocumentRecord({
      ...(doc ? doc.toJS() : {}),
      matterNotes: matterNotes && matterNotes.notes ? matterNotes.notes : ''
    });
  }

  getDocuments(type) {
    return this.props.matter.get('deletedArt')
      .map(docNumber => this.getDocument(docNumber))
      .filter(doc => doc && doc.get('type') === type);
  }

  onSelectTab(type) {
    this.setState({ type });
  }

  onSelectNotesTab(notes) {
    if (notes === 'matterNotes') {
      this.props.loadMatterNotes(this.props.matter.get('id'));
    }

    this.setState({ notes });
  }

  onChange(e) {
    const type = e.target.getAttribute('data-type');
    if (type) {
      const docs = this.getDocuments(type).map(doc => doc.get('documentNumber')).toJS();
      this.setState({
        selected: e.target.checked
          ? _uniq(this.state.selected.concat(docs))
          : this.state.selected.filter(doc => !docs.includes(doc))
      });
      return;
    }

    this.setState({
      selected: e.target.checked
        ? this.state.selected.concat(e.target.value)
        : this.state.selected.filter(doc => doc !== e.target.value)
    });
  }

  isSelected(doc) {
    return this.state.selected && this.state.selected.includes(doc.get('documentNumber'));
  }

  renderRow(doc, i) {
    return (
      <tr key={i}>
        <td style={{ textAlign: 'center' }}>
          <input
            type='checkbox'
            checked={this.isSelected(doc)}
            value={doc.get('documentNumber')}
            onChange={this.onChange}
          />
        </td>
        <td>
          {doc.get('documentNumber')}
        </td>
        {doc.get('type') === 'Foreign' && (
          <td className='text-center'>
            {doc.get('country')}
          </td>
        )}
        {doc.get('type') === 'Other' && (
          <td>
            {doc.get('nonPatentBib')}
          </td>
        )}
        {doc.get('type') !== 'Other' && (
          <td>
            {doc.get('publicationDate')}
          </td>
        )}
        {doc.get('type') === 'US' && (
          <td>
            {doc.get('inventor')}
          </td>
        )}
        {doc.get('type') === 'Foreign' && (
          <td>
            {doc.get('applicant')}
          </td>
        )}
        <td>
          {doc.get(this.state.notes)}
        </td>
        <td style={{ width: '40px' }}>
          <DocumentActions
            doc={doc}
            features={this.props.features}
            matter={this.props.matter}
            onAction={this.props.onAction}
            logAction={this.props.logAction}
          />
        </td>
      </tr>
    );
  }

  renderTable(type) {
    if (type !== this.state.type) {
      return;
    }

    const docs = this.getDocuments(type);
    if (!docs.size) {
      return (
        <p style={{ padding: '15px 0' }}>
          No deleted documents.
        </p>
      );
    }

    return (
      <div style={{ padding: '15px 0' }}>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th style={{ width: '40px' }} className='text-center'>
                <input
                  type='checkbox'
                  data-type={type}
                  checked={docs.every(this.isSelected)}
                  onChange={this.onChange}
                />
              </th>
              <th style={{ width: '160px' }}>
                Doc Number
              </th>
              {this.state.type === 'Foreign' && (
                <th style={{ width: '50px' }} className='text-center'>
                  CC
                </th>
              )}
              {this.state.type === 'Other' && (
                <th>
                  Description
                </th>
              )}
              {this.state.type !== 'Other' && (
                <th style={{ width: '120px' }} className='text-center'>
                  Issue/Pub Date
                </th>
              )}
              {this.state.type === 'US' && (
                <th style={{ width: '160px' }}>
                  Inventor
                </th>
              )}
              {this.state.type === 'Foreign' && (
                <th style={{ width: '160px' }}>
                  Applicant
                </th>
              )}
              <th>
                <NotesHeader
                  selectedTab={this.state.notes}
                  onSelectTab={this.onSelectNotesTab}
                />
              </th>
              <th style={{ width: '40px' }}>
                &nbsp;
              </th>
            </tr>
          </thead>
          <tbody>
            {docs.map(this.renderRow)}
          </tbody>
        </Table>
      </div>
    );
  }

  renderBody() {
    if (this.state.loading) {
      return (
        <div style={{ padding: '15px 0' }}>
          <Loading />
        </div>
      );
    }

    return (
      <div>
        <Tabs
          onSelect={this.onSelectTab}
          activeKey={this.state.type}
          defaultActiveKey='US'
          id='undelete-tabs'
        >
          <Tab eventKey='US' title={`US (${this.getDocuments('US').size})`}>
            {this.renderTable('US')}
          </Tab>
          <Tab eventKey='Foreign' title={`Foreign (${this.getDocuments('Foreign').size})`}>
            {this.renderTable('Foreign')}
          </Tab>
          <Tab eventKey='Other' title={`NPL (${this.getDocuments('Other').size})`}>
            {this.renderTable('Other')}
          </Tab>
        </Tabs>
      </div>
    );
  }

  render() {
    return (
      <Modal
        show={this.state.show}
        size='xl'
        onHide={this.onClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>
          Undelete documents from just this matter
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.renderBody()}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            onClick={this.onClose}
          >
            Cancel
          </Button>
          <Button
            disabled={this.state.saving}
            variant='primary'
            onClick={this.onConfirm}
          >
            {this.state.saving ? 'Saving...' : 'Confirm'}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
